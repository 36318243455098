<template>
  <div class="class_physical">
    <div class="main">
      <el-menu
        style="width: 100% !important"
        :default-active="activeIndex"
        class="el-menu-demo"
        router
        mode="horizontal"
        @select="handleSelect">
        <el-menu-item index="/attainment">体质健康档案</el-menu-item>
        <el-menu-item index="/classExamination">中考模考</el-menu-item>
      </el-menu>
      <div class="flexSe">
        <div class="align-center">
          <div class="align-center">
            <div class="class_select_name">班级：</div>
            <el-select
              @change="handleChangelevle"
              v-model="level_id"
              placeholder="年级"
              style="width: 120px; height: 35px">
              <el-option
                v-for="item in level_li"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select
              @change="handleChangeClass"
              style="margin-left: 20px; width: 120px; height: 35px"
              v-model="class_id"
              placeholder="班级">
              <el-option
                v-for="item in classlist"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="student_link ml-20">
            <div @click="handleToGodetail" class="align-center detail">
              <div class="nowrap">查看学生体质健康详情</div>
              <img class="img" src="@/assets/img/72.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="w100 align-center">
        <div class="align-center wb-80 row-right prb-25 fz-24"></div>
      </div>

      <div class="class_contain">
        <class-contain
          :recordData="recordData"
          :classBarData="classBarData"
          :totalNumber="totalNumber"
          :historicalList="historicalList"
          :school_record_id="school_record_id"
          :levelName="levelName"
          :className="className"
          @handleGenderSelect="handleGenderSelect"
          @handleTimeClick="handleTimeClick"
          @handlePreview="handlePreview" />
      </div>
      <class-physical-dialog
        :isDialog="isDialog"
        :recordData="recordData"
        :classBarData="classBarData"
        :totalNumber="totalNumber"
        :historicalList="historicalList"
        :school_record_id="school_record_id"
        :levelName="levelName"
        :className="className"
        @handleGenderSelect="handleGenderSelect"
        @handleTimeClick="handleTimeClick"
        @handleClose="handleClose" />
    </div>
  </div>
</template>

<script>
import ClassContain from "./components/classPhysical/ClassContain.vue";
import classPhysicalDialog from "./components/classPhysical/classPhysicalDialog.vue";
import {
  classPhysicalReport,
  getHistoricalMeasurement,
  getClassPhysicalDiagramData,
} from "@/api/physical.js";
import { getSchoolID } from "@/utils/auth";

export default {
  components: {
    ClassContain,
    classPhysicalDialog,
  },
  data() {
    return {
      activeIndex: "/attainment",
      level_id: "",
      levelName: "",
      level_li: [],

      class_id: "",
      className: "",
      classlist: [],

      school_id: "",
      isDialog: false,

      school_record_id: "",
      historicalList: [],
      classBarData: [],

      tabsList: [
        {
          lable: "体测档案",
          value: 1,
        },
        {
          lable: "日常表现",
          value: 2,
        },
      ],
      tabsActive: 1,

      gender: -1,
      recordData: {
        class_report: null,
        project_avg_report: null,
        class_report_str: null,
        class_physical_diagram: null,
      },
      totalNumber: 0,
    };
  },
  async mounted() {
    this.school_id = getSchoolID();
    this.getHistoricalMeasurement();
    await this.getConfig();
  },
  methods: {
    handleSelect(key, keyPath) {},
    handleSchool() {
      this.$router.push("/schoolPhysical");
    },

    handleEducation() {
      this.$router.push("/educationPhysical");
    },

    handleGrade() {
      this.$router.push("/gradePhysical");
    },

    handleTabsClick(value) {
      this.tabsActive = value;
    },
    // 获取班级下学生数据
    async getStudent() {
      const res = await this.axios.get(
        `/school/students/list?school_id=${this.school_id}&classes_id=${this.class_id}&per_page=1000&page=1`
      );
      if (res.code === 1) {
        this.totalNumber = res.data.total;
      }
    },
    handleClose() {
      this.isDialog = false;
    },
    handlePreview() {
      this.isDialog = true;
    },
    // 切换年级
    handleChangelevle() {
      this.levelName = this.level_li.find(
        (item) => item.id == this.level_id
      ).name;

      this.getClasses();
    },

    // 切换班级
    handleChangeClass() {
      this.className = this.classlist.find(
        (item) => item.id == this.class_id
      ).name;
      this.getClassReport();
      this.getPhysicalDiagramData();
      this.getStudent();
    },

    // 切换性别
    handleGenderSelect(gender) {
      this.gender = gender;
      this.getClassReport();
      this.getPhysicalDiagramData();
    },

    handleTimeClick(id) {
      this.school_record_id = id;
      this.getClassReport();
      this.getPhysicalDiagramData();
    },

    // 获取年级
    async getConfig() {
      const res = await this.axios.get(
        "/config/level?school_id=" + this.school_id
      );
      if (res.code === 1) {
        this.level_li = res.data;
        if (res.data.length > 0) {
          if (this.school_id == 72) {
            this.level_id = 3;
            this.levelName = "三年级";
          } else {
            this.level_id = res.data[0].id;
            this.levelName = res.data[0].name;
          }
        }
        this.getClasses();
      }
    },

    // 获取班级
    async getClasses() {
      const res = await this.axios.get(
        `/classes?all=1&school_id=${this.school_id}&level_id=${this.level_id}`
      );
      if (res.code === 1) {
        this.classlist = res.data;
        if (res.data.length > 0) {
          this.class_id = res.data[0].id;
          this.className = res.data[0].name;
          this.getStudent();
          this.getClassReport();
          this.getPhysicalDiagramData();
        } else {
          this.class_id = "";
          this.className = "";
          this.totalNumber = 0;
          this.getClassReport();
          this.getPhysicalDiagramData();
        }
      }
    },

    async getHistoricalMeasurement() {
      const params = {
        school_id: this.school_id,
      };
      const { data } = await getHistoricalMeasurement(params);

      this.historicalList = data;
      if (data && data.length) {
        if (this.school_id == 72) {
          this.school_record_id = data[1].id;
        } else {
          this.school_record_id = data[0].id;
        }
      }
    },

    handleBack() {
      this.$router.go(-1);
    },

    handleToGodetail() {
      this.$router.push("/studentPhysical");
    },

    // 获取班级信息
    async getClassReport() {
      const { school_id, class_id, level_id, gender, school_record_id } = this;

      // const params = removeEmpty({
      // school_id,
      // class_id,
      // grade: level_id,
      // gender,
      // school_record_id,
      // });

      const params = {
        school_id,
        class_id,
        grade: level_id,
        gender,
      };

      if (school_record_id) {
        params.school_record_id = school_record_id;
      }

      const { data, code } = await classPhysicalReport(params);

      this.recordData = data;
    },

    async getPhysicalDiagramData() {
      const { class_id, school_record_id, gender } = this;
      const that = this;
      const params = {
        class_id,
        school_record_id,
        gender,
      };

      const { data, code } = await getClassPhysicalDiagramData(params);

      if (code == 1) {
        this.classBarData = data;
      }
    },
  },
};
</script>

<style scoped lang="less">
.class_physical {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: stretch;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100%;
}
.main {
  flex: 1;
  background-color: #fff;
  border-radius: 4px;
  padding: 35px 25px;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
}
.el-menu {
  width: 100% !important;
}
.el-menu--horizontal {
  border: 0;
}
.el-menu-item {
  .is-active {
    border-bottom: 2px solid #409eff;
    color: #303133;
  }
}
@import "./less/classPhysical.less";
</style>
