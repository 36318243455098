// 等级率值
export const Grade_Rate_Value_Echart = {
    title: {
        text: '等级率值',
        left: 'center',
        textStyle: {
            color: '#101010',
            fontSize: '20'
        }
    },
    tooltip: {
        trigger: 'item',
        formatter: function (record) {
            return `${record.marker} ${record.name} ${record.value}%`
        }
    },
    legend: [
        {
            orient: 'horizontal',
            bottom: '8%',
            left: 'center',
            textStyle: {
                color: '#444444',
                fontSize: 18
            },
            data: ['优秀率', '良好率'],
            itemGap: 15,
        },
        {
            orient: 'horizontal',
            bottom: 'bottom',
            left: 'center',
            textStyle: {
                color: '#444444',
                fontSize: 18
            },
            data: ['及格率', '不及格率'],
            itemGap: 15,
        },
    ],
    series: [
        {
            name: '',
            type: 'pie',
            radius: '50%',
            label: {
                show: true,
                color: '#333',
                fontSize: 14,
                overflow: 'none'
            },
            labelLine:{
                length: 6,
                length2: 6
            },
            data: [
                { value: 0, name: '优秀率' },
                { value: 0, name: '良好率' },
                { value: 0, name: '及格率' },
                { value: 0, name: '不及格率' },
            ],
        }
    ]
}

export const Sports_Events_Enum = [
    {
        id: 1,
        name: 'BMI',
        unit: '千克/米²',
        type: 'BMI',
        key: 'student_BMI',
        scoreKey: 'student_BMI_score',
        value: 0,
        score: 0,
        icon: require('@/assets/img/115.png'),
        iconStyle: 'width: 31px; height: 41px',
    },
    {
        id: 2,
        name: '肺活量',
        key: 'student_lung',
        type: 'LUNG',
        scoreKey: 'student_lung_score',
        unit: '毫升',
        value: 0,
        score: 0,
        icon: require('@/assets/img/116.png'),
        iconStyle: 'width: 33px; height: 30px',
    },
    {
        id: 3,
        name: '50米跑',
        unit: '秒',
        type: 'RUNNING_50METERS',
        key: 'student_50meters',
        scoreKey: 'student_50meters_score',
        value: 0,
        score: 0,
        icon: require('@/assets/img/117.png'),
        iconStyle: 'width: 31px; height: 35px',
    },
    {
        id: 4,
        name: '坐位体前屈',
        unit: '厘米',
        key: 'student_stretch',
        type: 'STRETCH',
        scoreKey: 'student_stretch_score',
        value: 0,
        score: 0,
        icon: require('@/assets/img/118.png'),
        iconStyle: 'width: 34px; height: 30px',
    },
    {
        id: 5,
        name: '1分钟跳绳',
        unit: '次',
        key: 'JUMP',
        type: 'JUMP',
        key: 'student_1min_rope',
        scoreKey: 'student_1min_rope_score',
        value: 0,
        score: 0,
        icon: require('@/assets/img/119.png'),
        iconStyle: 'width: 28px; height: 38px',
    },
    {
        id: 6,
        name: '1分钟仰卧起坐',
        unit: '次',
        key: 'student_1min_sit_up',
        type: 'SIT_UP',
        scoreKey: 'student_1min_sit_up_score',
        value: 0,
        score: 0,
        icon: require('@/assets/img/120.png'),
        iconStyle: 'width: 34px; height: 24px',
    },
    {
        id: 7,
        name: '50米×8往返跑',
        unit: '分·秒',
        key: 'student_50meters_x8',
        type: 'RUNNING_50METERS_X8',
        scoreKey: 'student_50meters_x8_score',
        value: 0,
        score: 0,
        icon: require('@/assets/img/117.png'),
        iconStyle: 'width: 31px; height: 35px',
    },
    {
        id: 8,
        name: '立定跳远',
        unit: '厘米',
        key: 'student_standing_jump',
        type: 'STANDING_JUMP',
        scoreKey: 'student_standing_jump_score',
        value: 0,
        score: 0,
        icon: require('@/assets/img/121.png'),
        iconStyle: 'width: 29px; height: 31px',
    },
]

export const Gender_List_Enum = [
    {
        label: "不分性别",
        value: -1,
    },
    {
        label: "女",
        value: 0,
    },
    {
        label: "男",
        value: 1,
    },
]

export const Subproject_XAxis_Enum1 = [
    {
        key: 'student_BMI',
        name: 'BMI'
    },
    {
        key: 'student_lung',
        name: '肺活量'
    },
    {
        key: 'student_50meters',
        name: '50米跑'
    }, {
        key: 'student_stretch',
        name: '坐位体前屈'
    }, {
        key: 'student_1min_rope',
        name: '1分钟跳绳'
    }, {
        key: 'student_1min_sit_up',
        name: '1分钟仰卧起坐'
    }, {
        key: 'student_50meters_x8',
        name: '50米×8往返跑'
    }, {
        key: 'student_standing_jump',
        name: '立定跳远'
    },
]

export const Subproject_XAxis_Enum2 = {
    student_BMI: 'BMI',
    student_lung: '肺活量',
    student_50meters: '50米跑',
    student_stretch: '坐位体前屈',
    student_1min_rope: '1分钟跳绳',
    student_1min_sit_up: '1分钟仰卧起坐',
    student_50meters_x8: '50米×8往返跑',
    student_standing_jump: '立定跳远',
}

export const Subproject_Rate_Value_Echart = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        },
        formatter: function (record) {
            let str = record[0].name + '</br>'

            for (let index = 0; index < record.length; index++) {
                str += `${record[index].marker}${record[index].seriesName}: ${record[index].value}% </br>`
            }
            return str
        }
    },
    legend: {},
    grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
    },
    xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
            color: '#444444',
            fontSize: '16',
        },
    },
    yAxis: {
        type: 'value',
        axisLabel: {
            color: '#444444',
            fontSize: '16',
            formatter: function (value, index) {
                return value + '%';
            }
        },
        max: 100

    },
    color: ['#4388D6', '#3AAA2E', '#F1C344', '#F25757'],
    series: [
        {
            name: '优秀率',
            type: 'bar',
            stack: 'total',
            label: {
                show: true,
                color: '#fff',
                fontSize: 16,
                formatter: function (record) {
                    return record.value && record.value !== '0.00' ? record.value + '%' : ''
                }
            },
            emphasis: {
                focus: 'series'
            },
            data: []
        },
        {
            name: '良好率',
            type: 'bar',
            stack: 'total',
            label: {
                show: true,
                color: '#fff',
                fontSize: 16,
                formatter: function (record) {
                    return record.value && record.value !== '0.00' ? record.value + '%' : ''
                }
            },
            emphasis: {
                focus: 'series'
            },
            data: []
        },
        {
            name: '及格率',
            type: 'bar',
            stack: 'total',
            label: {
                show: true,
                color: '#fff',
                fontSize: 16,
                formatter: function (record) {
                    return record.value && record.value !== '0.00' ? record.value + '%' : ''
                }
            },
            emphasis: {
                focus: 'series'
            },
            data: []
        },
        {
            name: '不及格率',
            type: 'bar',
            stack: 'total',
            label: {
                show: true,
                color: '#fff',
                fontSize: 16,
                formatter: function (record) {
                    return record.value && record.value !== '0.00' ? record.value + '%' : ''
                }
            },
            emphasis: {
                focus: 'series'
            },
            data: []
        },
    ]
}

export const Test_Conclusion_Echart = {
    tooltip: {
        trigger: 'item',
    },
    radar: {
        indicator: [
            { name: 'BMI', max: 100, min: 0 },
            { name: '坐位体前屈', max: 100, min: 0 },
            { name: '1分钟跳绳', max: 100, min: 0 },
            { name: '1分钟仰卧起坐', max: 100, min: 0 },
            { name: '50米跑', max: 100, min: 0 },
            { name: '肺活量', max: 100, min: 0 },
        ],
        center: ["50%", "50%"],
        axisName: {
            color: '#5087EC',
            fontSize: 19
        },
        splitArea: {
            areaStyle: {
                color: ['#F7FCFF']
            }
        },
        splitLine: {
            lineStyle: {
                color: ['#E2E3E4']
            }
        },
        axisLine: {
            show: false,
        },
        
    },
    series: [
        {
            name: '体质健康综合分析',
            type: 'radar',
            color: '#5087EC',
            areaStyle: {
                color: "rgba(80, 135, 236, 0.4)"
            },
            data: [
                {
                    value: [],
                    name: ''
                },
            ]
        }
    ]
}

export const Physical_Health_Trends_Option = {
    title: {
        text: "BMI",
        left: "center", // 标题居中
        bottom: 0, // 距离上边框20像素
    },
    xAxis: {
        type: "category",
        data: ['2023-10-28', '2023-10-28', '2023-10-28', '2023-10-28', '2023-10-28', '2023-10-28', '2023-10-28', '2023-10-28', '2023-10-28'],
        axisLabel: {
            formatter: (value) => {
                // return value.split(` `)[0]; // return this.formatXlabel (value)
                return value.split(` `).join(`\n`); // return this.formatXlabel (value)
            },
            rotate: 45,
        },
        axisLabel: {
            color: '#444444',
            fontSize: '16',
        },
    },
    yAxis: {
        type: "value",
        axisLabel: {
            color: '#444444',
            fontSize: '16',
        },
    },
    grid: {
        bottom: "20%",
        top: "10%",
        left: "8%",
        right: "5%",
    },
    dataZoom: [
        {
            orient: "horizontal",

            show: false, //控制滚动条显示隐藏

            realtime: true, //拖动滚动条时是否动态的更新图表数据

            height: 15, //滚动条高度

            start: 0, //滚动条开始位置（共100等份）

            end: 100, //滚动条结束位置

            bottom: "4%",

            zoomLock: false, //控制面板是否进行缩放
        },

        {
            type: "inside",

            brushSelect: true,

            start: 0,

            end: 100,

            xAxisIndex: [0],
        },
    ],
    tooltip: {
        formatter: "{b}：{c}", //a 是series中每条数据name值，c 是data数值
    },
    series: [
        {
            data: [80, 70, 60, 50, 80, 95, 80, 78, 98],
            type: "bar",
            color: "#5087EC",
            barMaxWidth: 30,
        },
    ],
}

export const physical_Health_Trends_Echart = {
    title: {
        text: "BMI",
        left: "center", // 标题居中
        bottom: 0, // 距离上边框20像素
    },
    xAxis: {
        type: "category",
        data: [],
        axisLabel: {
            formatter: (value) => {
                return value.split(` `).join(`\n`); // return this.formatXlabel (value)
            },
            rotate: 45,
            color: '#444444',
            fontSize: '14',
        },
    },
    yAxis: {
        type: "value",
        axisLabel: {
            color: '#444444',
            fontSize: '14'
        },
        max: 100,
    },
    grid: {
        bottom: "28%",
        top: "10%",
        left: "15%",
        right: "5%",
    },
    dataZoom: [
        {
            orient: "horizontal",

            show: false, //控制滚动条显示隐藏

            realtime: true, //拖动滚动条时是否动态的更新图表数据

            height: 15, //滚动条高度

            start: 0, //滚动条开始位置（共100等份）

            end: 100, //滚动条结束位置

            bottom: "4%",

            zoomLock: false, //控制面板是否进行缩放
        },

        {
            type: "inside",

            brushSelect: true,

            start: 0,

            end: 100,

            xAxisIndex: [0],
        },
    ],
    tooltip: {
        formatter: "{b}：{c}", //a 是series中每条数据name值，c 是data数值
    },
    series: [
        {
            data: [],
            type: "bar",
            color: "#5087EC",
            barMaxWidth: 30,
        },
    ],
}

export const Rate_Value_Enum = [
    {
        key:'Excellence',
        name:'优秀率'
    },
    {
        key:'Good',
        name:'良好率'
    },
    {
        key:'Pass',
        name:'及格率'
    },
    {
        key:'Failure',
        name:'不及格率'
    },
]