<template>
  <div class="contain wb100">
    <div class="card_title_box w100 align-center space-between">
      <div class="align-center">
        <div class="card_title">班级体测概况</div>
        <div
          class="card_icon align-center pointer"
          v-if="!isDialog"
          @click="handlePreview">
          <div class="icon_img center">
            <img src="@/assets/img/113.png" alt="" />
          </div>
          <div>预览</div>
        </div>
        <div class="card_icon ml-20 align-center pointer">
          <div>
            体测日期:
            {{ class_report.record_time ? class_report.record_time : "---" }}
          </div>
        </div>
      </div>
      <div class="card_icon" v-if="!isDialog">
        <el-popover
          placement="bottom"
          width="153"
          trigger="click"
          v-model="isHistorical">
          <div class="historical_select_time">
            <div
              class="select_time_link"
              :class="item.id == school_record_id ? 'select_time_active' : ''"
              @click="handleTimeClick(item)"
              v-for="(item, index) in historicalList"
              :key="index">
              {{ item.record_time }}
            </div>
          </div>
          <div slot="reference" class="align-center pointer">
            <div class="icon_img center">
              <img src="@/assets/img/125.png" alt="" />
            </div>
            <div>历史体测</div>
          </div>
        </el-popover>
      </div>
    </div>
    <div class="align-center w100 overview_box">
      <div class="column data1_box bg1 align-center">
        <div class="title align-center">
          {{ levelName }}{{ className ? `${className}班` : "---" }}
        </div>
        <div class="align-center w100 space-between mb-32">
          <div class="align-center text1">
            <span>全部：</span>
            <span>{{ totalNumber ? `${totalNumber}人` : "---" }}</span>
          </div>
          <div class="align-center text1">
            <span>已测：</span>
            <span>{{
              class_report && class_report.student_num
                ? `${class_report.student_num}人`
                : "---"
            }}</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="bottom_box column align-center">
          <div class="number nowrap">
            {{
              class_report && class_report.total_score
                ? `${class_report.total_score}分`
                : "---"
            }}
          </div>
          <div class="img_bg">
            <img src="@/assets/img/114.png" alt="" />
          </div>
          <div class="bottom_text">平均总分</div>
        </div>
      </div>
      <div class="data2_box bg1">
        <div class="wh100" ref="gradeRateValueRef"></div>
      </div>
      <div class="sports_event_box flex-wrap">
        <template v-if="sportsEventsList && sportsEventsList.length">
          <div
            class="sport_event bg1 relative hidden"
            v-for="(item, index) in sportsEventsList"
            :key="index">
            <div
              class="subscript"
              :class="`status${item.levelNum ? item.levelNum : 3}`">
              {{ item.levelName ? item.levelName : "---" }}
            </div>
            <div class="sport_event_card align-end column">
              <div class="top_name">{{ item.name }}</div>
              <div class="top_unit">({{ item.unit }})</div>
            </div>
            <div class="sport_event_card align-end space-between">
              <div class="icon_box align-center">
                <div class="icon" :style="item.iconStyle">
                  <img :src="item.icon" alt="" />
                </div>
                <div class="score">
                  {{
                    item.score || item.score == 0 ? item.score + "分" : "---"
                  }}
                </div>
              </div>
              <div
                class="value"
                :class="timeRecord.includes(item.key) && 'time-value'">
                {{ item.value || item.value == 0 || "---" }}
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="wh100 center">
            <el-empty description="暂无数据"></el-empty>
          </div>
        </template>
      </div>
    </div>

    <div class="card_box w100">
      <div class="card_title_box w100 align-center space-between">
        <div class="card_title">分项目率值</div>
        <div class="gender_box">
          <div class="align-center">
            <div class="label">性别:</div>
            <el-select
              @change="handleGenderSelect"
              v-model="genderIndex"
              placeholder="请选择性别"
              style="width: 120px; height: 35px">
              <el-option
                v-for="item in genderList"
                :key="item.id"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="w100 card_contain1 card_contain">
        <div class="wh100" ref="subprojectRateValueRef"></div>
      </div>
    </div>

    <div class="card_box w100">
      <div class="card_title_box w100 align-center space-between">
        <div class="card_title">测试结论</div>
        <div class="gender_box">
          <div class="align-center">
            <div class="label">性别:</div>
            <el-select
              @change="handleGenderSelect"
              v-model="genderIndex"
              placeholder="请选择性别"
              style="width: 120px; height: 35px">
              <el-option
                v-for="item in genderList"
                :key="item.id"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="w100 card_contain2 align-center card_contain">
        <div
          class="conclusion"
          v-if="
            class_report_str &&
            (class_report_str.class_total_report_str ||
              class_report_str.class_part_str ||
              class_report_str.class_compare_str)
          ">
          <p v-if="class_report_str.class_total_report_str">
            1.{{ class_report_str.class_total_report_str }}
          </p>
          <p v-if="class_report_str.class_part_str">
            2.{{ class_report_str.class_part_str }}
          </p>
          <p v-if="class_report_str.class_compare_str">
            3.{{ class_report_str.class_compare_str }}
          </p>
        </div>
        <div class="conclusion center" v-else>
          <el-empty description="暂无数据"></el-empty>
        </div>
        <div class="testConclusion">
          <div class="wh100" ref="testConclusionRef"></div>
        </div>
      </div>
    </div>

    <div class="card_box w100">
      <div class="card_title_box w100 align-center">
        <div class="card_title">体质健康趋势</div>
      </div>
      <div class="w100 card_contain3 flex-wrap card_contain">
        <template v-if="physicalHealthList && physicalHealthList.length">
          <div
            class="contain"
            v-for="(item, index) in physicalHealthList"
            :key="index">
            <div class="containHealth wh100" ref="containHealthRef"></div>
          </div>
        </template>
        <template v-else>
          <div class="no-data wh100 center">
            <el-empty description="暂无数据"></el-empty>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Grade_Rate_Value_Echart,
  Sports_Events_Enum,
  Gender_List_Enum,
  Subproject_Rate_Value_Echart,
  Test_Conclusion_Echart,
  physical_Health_Trends_Echart,
  Subproject_XAxis_Enum1,
  Subproject_XAxis_Enum2,
  Rate_Value_Enum,
} from "../../enums/classPhysicalEnum.js";
import * as echarts from "echarts";
export default {
  props: {
    isDialog: {
      type: Boolean,
      default: false,
    },
    school_record_id: {
      type: Number | String,
      default: 0,
    },
    className: {
      type: String,
      default: "",
    },
    levelName: {
      type: String,
      default: "",
    },
    totalNumber: {
      type: Number,
      default: 0,
    },
    historicalList: {
      type: Array,
      default: [],
    },
    recordData: {
      type: Object,
      default: {},
    },
    classBarData: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      historical: "",
      isHistorical: false,

      gradeRateValueEchart: Grade_Rate_Value_Echart,
      gradeRateValue: null,

      sportsEventsList: JSON.parse(JSON.stringify(Sports_Events_Enum)),

      genderList: Gender_List_Enum,
      genderIndex: -1,

      subprojectRateValueEchart: Subproject_Rate_Value_Echart,
      subprojectRateValue: null,

      testConclusionEchart: Test_Conclusion_Echart,
      testConclusion: null,
      physicalHealthList: [],
      timeRecord: [
        "student_50meters_x8",
        "student_800meters",
        "student_1000meters",
      ],
    };
  },
  computed: {
    class_report() {
      const { recordData } = this;
      return recordData && recordData.class_report
        ? recordData.class_report
        : {};
    },
    class_physical_diagram() {
      const { recordData } = this;
      return recordData && recordData.class_physical_diagram
        ? recordData.class_physical_diagram
        : {};
    },
    class_report_str() {
      const { recordData } = this;
      return recordData && recordData.class_report_str
        ? recordData.class_report_str
        : {};
    },
    project_avg_report() {
      const { recordData } = this;
      return recordData && recordData.project_avg_report
        ? recordData.project_avg_report
        : {};
    },
  },
  mounted() {
    this.setSportsEventsList();
    this.setGradeRateValueEchart();
    this.setSubprojectRateValue();
    this.setTestConclusion();

    this.$nextTick(() => {
      this.WatchResize();
      this.handleEchartClick();
    });
  },
  watch: {
    recordData: {
      handler() {
        this.setGradeRateValueEchart();
        this.setSportsEventsList();
        this.setSubprojectRateValue();
        this.setTestConclusion();
      },
      deep: true,
    },
    classBarData: {
      handler(newVal, oldVal) {
        if (!oldVal || !oldVal.length) {
          this.physicalHealthListInit();
        }
        this.$nextTick(() => {
          this.setContainHealth(oldVal);
        });
      },
    },
  },
  methods: {
    setSportsEventsList() {
      const { class_report } = this;
      let codeArr = Sports_Events_Enum;
      codeArr.forEach((item) => {
        item.value = 0;
        item.score = 0;
        let objKeys = class_report ? Object.keys(class_report) : [];
        let keyIndex = objKeys.findIndex((key) => item.key == key);
        let scoreKeyIndex = objKeys.findIndex((key) => item.scoreKey == key);
        if (keyIndex !== -1) {
          item.value = class_report[objKeys[keyIndex]];
          item.levelNum = class_report[`${objKeys[keyIndex]}_level_num`];
          item.levelName = class_report[`${objKeys[keyIndex]}_level_name`];
        }

        if (scoreKeyIndex !== -1) {
          item.score = class_report[objKeys[scoreKeyIndex]];
        }
      });

      this.sportsEventsList = codeArr.filter((item) => {
        return item.value || item.score;
      });

      this.physicalHealthListInit();
      this.$nextTick(() => {
        this.setContainHealth();
      });
    },

    physicalHealthListInit() {
      const that = this;
      const { classBarData } = this;
      this.physicalHealthList = [];
      this.sportsEventsList.forEach((item, index) => {
        if (!that[`physicalHealth${index}`]) {
          that[`physicalHealth${index}`] = null;
        }

        let sportEcharts = {
          ...physical_Health_Trends_Echart,
          indepType: item.type,
          title: {
            text: item.name,
            left: "center", // 标题居中
            bottom: 0, // 距离上边框20像素
          },
        };

        this.physicalHealthList.push(sportEcharts);
      });
    },

    setGradeRateValueEchart() {
      const { class_report } = this;
      let myChart = this.$refs.gradeRateValueRef;
      if (this.gradeRateValue == null) {
        this.gradeRateValue = echarts.init(myChart);
      }

      this.gradeRateValueEchart.series[0].data.forEach((item, index) => {
        switch (index) {
          case 0:
            item.value =
              (class_report && class_report.student_total_level_1) || 0;
            break;
          case 1:
            item.value =
              (class_report && class_report.student_total_level_2) || 0;
            break;
          case 2:
            item.value =
              (class_report && class_report.student_total_level_3) || 0;
            break;
          case 3:
            item.value =
              (class_report && class_report.student_total_level_4) || 0;
            break;
        }
      });
      this.gradeRateValue.setOption(this.gradeRateValueEchart);
    },

    setSubprojectRateValue() {
      const { project_avg_report } = this; //Subproject_XAxis_Enum2
      let myChart = this.$refs.subprojectRateValueRef;

      if (this.subprojectRateValue == null) {
        this.subprojectRateValue = echarts.init(myChart);
      }

      this.subprojectRateValueEchart.xAxis.data =
        project_avg_report && project_avg_report.x_list
          ? project_avg_report.x_list.map((key) => {
              return Subproject_XAxis_Enum1.find((item) => item.key == key)
                .name;
            })
          : [];

      this.subprojectRateValueEchart.series.forEach((item, index) => {
        item.data =
          project_avg_report && project_avg_report.y_list
            ? project_avg_report.y_list[index]
            : [];
      });

      this.subprojectRateValue.setOption(this.subprojectRateValueEchart);
    },

    setTestConclusion() {
      const { class_report, sportsEventsList } = this;
      let myChart = this.$refs.testConclusionRef;
      if (this.testConclusion == null) {
        this.testConclusion = echarts.init(myChart);
      }

      let sportsArr = JSON.parse(JSON.stringify(sportsEventsList));
      let screenArr = sportsArr.filter((item) => {
        class_report &&
          Object.keys(class_report).forEach((key) => {
            if (key == item.scoreKey) {
              item.score = class_report[key];
            }
          });

        return item.score || item.score == 0;
      });

      let radarArr = screenArr && screenArr.length ? screenArr : sportsArr;

      this.testConclusionEchart.radar.indicator =
        radarArr && radarArr.length
          ? radarArr.map((item) => {
              return {
                name: item.name,
                max: 100,
                min: 0,
              };
            })
          : Test_Conclusion_Echart.radar.indicator;

      this.testConclusionEchart.series[0].data[0].value = radarArr.map(
        (item) => item.score
      );

      this.testConclusion.setOption(this.testConclusionEchart);
    },

    setContainHealth(oldVal = []) {
      let myCharts = this.$refs.containHealthRef;
      for (let index = 0; index < this.physicalHealthList.length; index++) {
        if (
          this[`physicalHealth${[index + 1]}`] == null ||
          !oldVal ||
          !oldVal.length
        ) {
          this[`physicalHealth${[index + 1]}`] = echarts.init(myCharts[index]);
        }

        if (this.classBarData.length) {
          this.classBarData.forEach((item1) => {
            Object.values(item1).forEach((item2) => {
              if (this.physicalHealthList[index].indepType == item2.type) {
                this.physicalHealthList[index].xAxis.data = item2.x_data;
                this.physicalHealthList[index].series[0].data = item2.y_data;
              }
            });
          });
        } else {
          this.physicalHealthList[index].xAxis.data = [];
          this.physicalHealthList[index].series[0].data = [];
        }

        this[`physicalHealth${[index + 1]}`].setOption(
          this.physicalHealthList[index]
        );
      }
    },

    handlePreview() {
      this.$emit("handlePreview");
    },

    WatchResize() {
      const that = this;
      window.addEventListener("resize", function () {
        that.gradeRateValue.resize();
        that.subprojectRateValue.resize();
        that.testConclusion.resize();
        for (let index = 0; index < this.physicalHealthList.length; index++) {
          this[`physicalHealth${[index + 1]}`].resize();
        }
      });
    },

    handleEchartClick() {
      const that = this;
      that.subprojectRateValue.on("click", function (params) {
        let sports = Sports_Events_Enum.find(
          (item) => item.name == params.name
        );

        let series = Rate_Value_Enum.find(
          (item) => item.name == params.seriesName
        );

        const sportObj = {
          rateName: series.name,
          rateKey: series.key,
          name: sports.name,
          key: sports.key,
          scoreKey: sports.scoreKey,
        };

        console.log(sportObj, "sportObj");
      });
    },

    handleTimeClick(item) {
      this.$emit("handleTimeClick", item.year);
      this.isHistorical = false;
    },

    handleGenderSelect() {
      this.$emit("handleGenderSelect", this.genderIndex);
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../less/classPhysical.less";
</style>
