<template>
  <transition name="dialog-fade">
    <div class="wrapper" v-if="isDialog">
      <div class="dialog">
        <div class="dialog_close">
          <div class="click_dom" @click="handleExport">
            <div class="icon">
              <img src="@/assets/img/124.png" alt="" />
            </div>
            <span>导出</span>
          </div>

          <div class="click_dom" @click="handleClose">
            <div class="icon">
              <img src="@/assets/img/123.png" alt="" />
            </div>
            <span>关闭</span>
          </div>
        </div>
        <div class="dialog_box">
          <div class="dialog-body" ref="dialogBodyRef" id="contentToExport">
            <div class="dialog-title">
              体质健康档案——{{ levelName
              }}{{ className ? `${className}班` : "---" }}
            </div>
            <div class="dialog-body-content">
              <class-contain
                :isDialog="isDialog"
                :recordData="recordData"
                :classBarData="classBarData"
                :totalNumber="totalNumber"
                :historicalList="historicalList"
                :school_record_id="school_record_id"
                :levelName="levelName"
                :className="className"
                @handleGenderSelect="handleGenderSelect"
                @handleTimeClick="handleTimeClick"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import htmlPdf from "@/utils/pdf.js";
import ClassContain from "./ClassContain.vue";
export default {
  components: {
    ClassContain,
  },
  props: {
    isDialog: {
      type: Boolean,
      default: false,
    },
    school_record_id: {
      type: Number | String,
      default: 0,
    },
    className: {
      type: String,
      default: "",
    },
    totalNumber: {
      type: Number | String,
      default: "",
    },
    levelName: {
      type: String,
      default: "",
    },
    historicalList: {
      type: Array,
      default: [],
    },
    recordData: {
      type: Object,
      default: {},
    },
    classBarData: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {};
  },
  computed: {
    studentName() {
      const { curentStudent } = this;
      return curentStudent && curentStudent.name
        ? curentStudent.name.substr(curentStudent.name.length - 1)
        : "---";
    },
  },
  mounted() {},
  watch: {},
  methods: {
    async handleExport() {
      let name = `体质健康档案——${this.levelName}${
        this.className ? `${this.className}班` : "---"
      }`;
      const dom = document.getElementById("contentToExport");
      htmlPdf(name, dom);
    },

    handleGenderSelect(genderIndex) {
      this.$emit("handleGenderSelect", genderIndex);
    },

    handleTimeClick(id) {
      this.$emit("handleTimeClick", id);
    },

    handleClose() {
      this.$emit("handleClose", false);
    },
  },
};
</script>

<style lang="less" scoped>
.wb100 {
  width: 100%;
  height: 100%;
}
.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5);
}

img {
  width: 100%;
  height: 100%;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog {
  position: relative;
  margin: 0 auto 50px;
  width: 80%;
  top: 4%;
  box-sizing: border-box;
  .dialog_close {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 23px;
    .click_dom {
      display: flex;
      align-items: center;
      color: #fff;
      cursor: pointer;
      margin-left: 38px;
      font-size: 22px;
      .icon {
        width: 35px;
        height: 35px;
        margin-right: 13px;
      }
    }
  }
}

.dialog-body {
  padding: 20px;
  // width: 100%;
  width: 97%;
}

.dialog_contain {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.dialog_box {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.dialog-title {
  font-size: 28px;
  color: #101010;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 40px;
}
.dialog-body-content {
  // width: 100%;
  width: 97%;
  border: 1px solid #baccfb;
  border-radius: 10px;
  padding: 25px 20px;
}
.ml-20 {
  margin-left: 20px;
}
</style>
